<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div class="bottom_space items-center flex justify-between p-2 cust_card">
        <div>
          <span class="flex items-center pl-3" >
            <div class="heading-3 font-bold cursor-pointer" v-for="(data, index) in routingData" :key="index">
              <span @click="redirectToFolder(data, index)" :style="`color: ${data.color} `">{{data.name}}</span>
              <span class="px-2 text-gray4" v-if="index != routingData.length - 1">></span>
            </div>
          </span>
        </div>
        <div class="flex">
          <button class="list_top_card_btn flex items-center justify-center bg-primary font-bold heading-6 text-white py-3 px-5 rounded-3xl" @click="addNewFolder('create')">
            <i class="fas fa-folder-plus h-5 w-5"></i>
            <span class="pl-1">CREATE FOLDER</span>
          </button>
          <button class="list_top_card_btn flex items-center justify-center bg-primary font-bold heading-6 text-white py-3 px-5 rounded-3xl" @click="addNewFolder('add')">
            <i class="fas fa-cloud-upload h-5 w-5"></i>
            <span class="pl-1">UPLOAD FILE</span>
          </button>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="cust_card">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 px-3 py-3 items-center">
          <div class="name_col  flex items-center p-2">
            <span class="">{{titleObj[0].title}}</span>
          </div>
          <div class="date_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn('uploadedOn', 1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort" ><i class="fas fa-sort-amount-up h-5 w-5 text-primary"></i></span>
            <p v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-5 w-5 text-primary"></i></p>
          </div>
          <div class="size_col  flex items-center p-2">
            <span class="cursor-pointer" @click="sortByColumn('size', 2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
            <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-5 w-5 text-primary"></i></span>
            <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-5 w-5 text-primary"></i></p>
          </div>
        </div>
        <div v-for="(data, index) in folderDataList" :key="index" class="min-w-min group   flex border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-2 items-center cursor-pointer hover:bg-gray-50">
          <div class="name_col p-2">
            <div class="heading-4  text-text2 font-medium flex" @dblclick="openSpecificFolder(data)">
              <div v-html="FileManagerFileIcon(data.fileName)"></div>
              <p class="pl-4" v-if="data.folderName !== ''">{{data.folderName}}</p>
              <p class="pl-4" v-else>{{data.fileName}}</p>
            </div>
          </div>
          <div class="date_col p-2">
            <div class="heading-4  text-text2 font-medium flex">
              <p v-if="data.uploadedOn !== ''">{{data.uploadedOn | dateTimeFilter}}</p>
              <p v-else>---</p>
            </div>
          </div>
          <div class="size_col p-2">
            <div class="heading-4  text-text2 font-medium flex">
              <p v-if="data.fileType !== ''">{{data.size | getFileSize(3)}}</p>
              <p v-else>---</p>
            </div>
          </div>
          <div class="p-2 absolute right-5">
            <div class="">
              <span class="ml-2 mr-2 p-1 text-gray4  heading-1 cursor-pointer"  @click.stop.passive="opendata(data)">
                <i class="fas fa-ellipsis-v"></i>
              </span>
              <div>
                <div v-click-outside="closeCopyDeleteMenu">
                  <div v-if="data.isOpen" class=" bg-white absolute z-10 right-3 shadow-2xl rounded -top-7">
                    <div style="max-width: fit-content;" class="">
                      <div class="p-2 text-text1 ">
                        <div class="px-6 py-3 cursor-pointer hover:bg-blue-50" v-if="data.fileType !== ''">
                          <span class="heading-4" @click="copyUrltoClip(data)">
                            <i class="fas fa-copy h-5 w-5"></i>
                            <span class="pl-2">Copy URL</span>
                          </span>
                        </div>
                        <!-- <div class="px-6 py-3 cursor-pointer hover:bg-blue-50">
                          <span class="heading-4">
                            <i class="far fa-edit h-5 w-5"></i>
                            <span class="pl-2">Rename</span>
                          </span>
                        </div> -->
                        <div class="px-6 py-3 cursor-pointer hover:bg-red-50"  @click="deleteselData(data)">
                          <span class="heading-4 text-error">
                            <i class="fas fa-trash h-5 w-5"></i>
                            <span class="pl-2">Delete</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="layout m-2" v-if="folderDataList.length === 0">
          <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
              <p class=" font-medium heading-4">No Folder/file found.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="addedFolderPopup" class="popup_overlay">
      <div class="bg-white rounded-sm custom_dialog" style="width:590px;max-height:750px;">
        <div class="cust_card">
          <div class=" rounded px-3 py-2 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
            <div v-if="showFolderName">
              <p class="">Add Folder</p>
            </div>
            <div v-if="showFileSel">
              <p class="">Add File</p>
            </div>
            <div>
              <div>
                <button class="font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="discardUploadFile()">Discard</button>
                <button v-if="showFolderName" :disabled="isDisable" :class="isDisable ? 'bg-gray-500 text-gray-200 filter opacity-60' : 'bg-success text-white'" class="font-bold cta-text py-2 px-4 rounded-3xl" @click="createFolder()">Create</button>
                <button v-if="showFileSel" :disabled="isDisable" :class="isDisable ? 'bg-gray-500 text-gray-200 filter opacity-60' : 'bg-success text-white'" class="font-bold cta-text py-2 px-4 rounded-3xl" @click="createFolder()">Save</button>
              </div> 
            </div>  
          </div>
          <div class="pb-4 p-2" style="max-height: 700px!important;overflow-y: auto;">
            <div class="grid grid-cols-12">
              <div class="box col-span-12 p-2" v-if="showFolderName">
                <p class="label_css">Folder Name</p>
                <input
                class="outline-none   text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 focus:border-gray-800 border border-gray-500" 
                placeholder="Folder Name"
                v-model="newFolObj.folderName"
                >
              </div>
              <div class="box col-span-12 p-2" v-if="showFileSel">
                <div class="flex justify-between">
                  <p class="heading-4">Upload File</p>
                  <input
                    type="file"
                    ref="fileSelect"
                    multiple
                    style="display: none"
                    @change="previewFiles($event.target.files)"
                  />
                  <button @click="$refs.fileSelect.click()" class=" flex items-center justify-center bg-primary font-bold heading-6 text-white py-1 px-4 rounded" >
                    <i class="fas fa-cloud-upload h-5 w-5"></i>
                    <span class="pl-1">Select File</span>
                  </button>
                </div>
                <div v-for="(data, index) in newFolObj.file" :key="index + `a`" class="pt-4">
                  <div class="subheading mb-0 font-weight-medium flex items-center">
                    <div v-html="FileManagerFileIcon(data.name)"></div>
                    <span class="pl-2">{{data.name}} ({{data.size | getFileSize(3)}}) </span>
                    <span @click="removeSelecteduploadFile(index)" class="pl-2">
                      <i class="h-5 w-5 fas fa-close text-error cursor-pointer" ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="deleteSpecPage" class="popup_overlay">
      <div style="height: 120px; width: 500px;" class="custom_dialog">
        <div class="text-text1 px-4 py-3 flex justify-between items-center">
          <span class="heading-3">Are You Sure ?</span>
          <div class="flex justify-end">
            <button class="bg-error text-white shadow mr-2 rounded-full px-5 py-1.5" @click="deleteSpecPage = false">No</button>
            <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="removeData()">Yes</button>
          </div>
        </div>
        <hr>
        <div class="p-4">
          <p class="text-gray3 heading-4">Once delete cannot be undone !</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HRMAPI from '@/View/CMS/api/HRM.js'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
// import axios from 'axios'
export default {
  components: {
  },
  mixins: [deboucneMixin],
  data () {
    return {
      selectedTempData: [],
      deleteSpecPage: false,
      isDisable: true,
      showFileSel: true,
      showFolderName: true,
      routingData: [],
      IsSuperAdmin: false,
      mobileView: false,
      redirectPath: '',
      createFolderInsideFolderPath: '',
      folderDataList: [],
      titleObj: [
        {title: 'Name', icon: '', value: 'fileName', sort: false},
        {title: 'Date', icon: '', value: 'uploadedOn', sort: true},
        {title: 'Size', icon: '', value: 'size', sort: true},
      ],
      sendObj: {
        projectDetailId: '',
        folderPath: '',
        shortBy: '',
        sortOrder: 'DESC'
      },
      pagination: {
        sortBy: 'uploadedOn',
        descending: true
      },
      newFolObj: {
        folderPath: '',
        folderName: '',
        file: []
      },
      addedFolderPopup: false,
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    // this.sortByColumn('uploadedOn', 1, true, 'uploadedOn')
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData1'))
    this.IsSuperAdmin = Isadmin.isSuperAdmin
    localStorage.setItem('projectId', parseInt(this.$route.params.projectId))
    console.log(' parseInt(this.$route.params.projectId) 1',  parseInt(this.$route.params.projectId))
    this.resizeWindowHandler();
    document.title = 'Folder'
    this.getFolderList(this.redirectPath)
    let obj = {
      name: 'Folder',
      value: '',
      sendPath: '',
      color: '#494949'
    }
    this.routingData.push(obj)
  },
  watch: {
    newFolObj: {
      handler () {
        console.log('this.newFolObj', this.newFolObj)
        if (this.newFolObj.folderName !== '' || this.newFolObj.file.length > 0) {
          this.isDisable = false
        } else {
          this.isDisable = true
        }
      },
      deep: true
    },
    createFolderInsideFolderPath: {
      handler () {
        console.log('WATCH-------------------createFolderInsideFolderPath', this.createFolderInsideFolderPath)
      }
    },
    redirectPath: {
      handler () {
        console.log('WATCH-------------------redirectPath', this.redirectPath)
      }
    },
    pagination: {
      handler () {
        console.log('WATCH-------------------pagination', this.pagination)
      },
      deep: true
    },
    titleObj: {
      handler () {
        console.log('WATCH-------------------titleObj', this.titleObj)
      },
      deep: true
    }
  },
  methods: {
    addNewFolder (value) {
      if (value === 'create') {
        this.showFolderName = true
        this.showFileSel = false
      } else if (value === 'add') {
        this.showFolderName = false
        this.showFileSel = true
      }
      console.log('click')
      this.addedFolderPopup = true
    },
    createFolder () {
      console.log('this.createFolderInsideFolderPath', this.createFolderInsideFolderPath)
      console.log('this.newFolObj.file.file', this.newFolObj.file)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      let tempPath = ''
      if (this.createFolderInsideFolderPath === '' || this.createFolderInsideFolderPath === 'Folder') {
        console.log('1-------this.createFolderInsideFolderPath', this.createFolderInsideFolderPath)
        console.log('1-------this.newFolObj.folderName', this.newFolObj.folderName)
        if (this.newFolObj.folderName !== '') {
          tempPath = this.newFolObj.folderName + '/'
        } else {
          tempPath = ''
        }
        console.log('1-------tempPath', tempPath)
      } else {
        if (this.newFolObj.folderName === '' ) {
          console.log('2-------this.createFolderInsideFolderPath', this.createFolderInsideFolderPath)
          console.log('2-------this.newFolObj.folderName', this.newFolObj.folderName)
          for (let index = 0; index < this.routingData.length; index++) {
            if (this.routingData[index].name === this.createFolderInsideFolderPath) {
              tempPath = this.routingData[index].sendPath + this.routingData[index].name + '/'
            }
            console.log('2-------tempPath', tempPath)
          }
        } else {
          console.log('3-------this.createFolderInsideFolderPath', this.createFolderInsideFolderPath)
          console.log('3-------this.newFolObj.folderName', this.newFolObj.folderName)
          for (let index = 0; index < this.routingData.length; index++) {
            console.log('click 12312 3123', this.routingData[index])
            if (this.routingData[index].name === this.createFolderInsideFolderPath) {
              tempPath = this.routingData[index].sendPath + this.routingData[index].name + '/' + this.newFolObj.folderName + '/'
            }
            console.log('3-------tempPath', tempPath)
          }
        }
      }
      this.newFolObj.folderPath = tempPath
      HRMAPI.CreateNewFolder(
        parseInt(this.$route.params.projectId),
        this.newFolObj.folderPath,
        this.newFolObj.folderName,
        this.newFolObj.file,
        response => {
          this.discardUploadFile()
          console.log("response", response.Data)
          this.getFolderList(this.redirectPath)
          this.addedFolderPopup = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    uploadFiles () {
      console.log('click')
    },
    closeCopyDeleteMenu () {
      for (let index = 0; index < this.folderDataList.length; index++) {
        this.folderDataList[index].isOpen = false
      }
    },
    copyUrltoClip (value) {
      navigator.clipboard.writeText(value.fileUrl)
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'Url Copy to clipboard', color: 'success'})
    },
    previewFiles (fileList) {
      console.log('fileList', fileList)
      let notAllowExtenssion = ['exe', 'dll', 'pif', 'msi', 'com', 'msc', 'jar', 'bat', 'cmd', 'vbe', 'js', 'jse', 'deb', 'jar', 'ws', 'wsf', 'inf', 'reg', 'html']
      let invalidFile = ''
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i].name
        let fileNameAry = file.split('.')
        let ext = fileNameAry[fileNameAry.length - 1]
        if (notAllowExtenssion.indexOf(ext.toLowerCase()) >= 0) {
          invalidFile += file + ','
        } else {
          this.newFolObj.file.push(fileList[i])
        }
      }
      if (invalidFile !== '') {
        this.$store.dispatch('SetAlert', {showAlert: true, message: 'Invalid file extenssion for: ' + invalidFile, color: 'error'})
      }
    },
    openSpecificFolder (value) {
      if (value.fileName === '' && value.fileVaultId === 0 && value.folderName !== '') {
        console.log('openSpecificFolder', value)
        this.createFolderInsideFolderPath = value.folderName
        this.redirectPath = value.s3folderPath
        let obj = {
          name: value.folderName,
          value: value.s3folderPath,
          sendPath: '',
          color: '#494949'
        }
        console.log('this.routingData[index]', this.routingData)
        for (let index = 0; index < this.routingData.length; index++) {
          console.log('this.routingData[index]', this.routingData[index])
          this.routingData[index].color = '#317ED2'
          if (this.routingData[index].name !== 'Folder') {
            obj.sendPath += this.routingData[index].name + '/'
          }
        }
        this.routingData.push(obj)
        this.getFolderList(this.redirectPath)
      }
    },
    redirectToFolder (value, index) {
      console.log('redirectToFolder', value)
      this.createFolderInsideFolderPath = value.name
      if (this.routingData.length === 1) {
        this.routingData[0].color = '#494949'
      }
      if (value.color !== '#494949') {
        if (value.name !== 'Folder') {
          console.log('this.routingData', value)
          console.log('this.routingData', index)
          console.log('this.routingData', this.routingData)
          this.routingData.splice(index + 1, 100)
          for (let index = 0; index < this.routingData.length; index++) {
            if (this.routingData[index].name === value.name) {
              this.routingData[index].color = '#494949'
            }
          }
          this.redirectPath = value.value
          this.getFolderList(this.redirectPath)
        } else {
          this.routingData = []
          let obj = {
            name: 'Folder',
            value: '',
            sendPath: '',
            color: '#494949'
          }
          this.routingData.push(obj)
          this.redirectPath = value.value
          this.getFolderList(this.redirectPath)
        }
      }
    },
    opendata (data) {
      if (data.fileVaultId === 0 && data.folderName !== '') {
        this.folderDataList.forEach(element => {
          if ( element.folderName === data.folderName) {
            element.isOpen = true
          } else {
            element.isOpen = false
          }
        });
      } else {
        this.folderDataList.forEach(element => {
          if ( element.fileName === data.fileName) {
            element.isOpen = true
          } else {
            element.isOpen = false
          }
        });
      }
      console.log('data', data)
    },
    deleteselData(data) {
      this.selectedTempData = data
      this.deleteSpecPage = true
    },
    removeData () {
      this.deleteSpecPage = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      let reqType = ''
      let filePath = ''
      if (this.selectedTempData.fileType === '') {
        reqType = 'folder'
      } else {
        reqType = 'file'
      }
      if (this.selectedTempData.fileType === '') {
        filePath = this.selectedTempData.s3folderPath
      } else {
        filePath = this.selectedTempData.uploadFilePath
      }
      filePath
      HRMAPI.DeleteFolderData(
        parseInt(this.$route.params.projectId),
        reqType,
        filePath,
        this.selectedTempData.fileVaultId,
        response => {
          this.deleteSpecPage = false
          this.getFolderList(this.redirectPath)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    removeSelecteduploadFile (index) {
      this.newFolObj.file.splice(index, 1)
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    discardUploadFile () {
      console.log('clear clear clear clear clear clear clear')
      this.addedFolderPopup = false
      this.newFolObj.file = []
      this.newFolObj.folderName = ''
      this.newFolObj.folderPath = ''
    },
    getFolderList (value) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      this.folderDataList = []
      this.sendObj.folderPath = value
      this.sendObj.projectDetailId = parseInt(this.$route.params.projectId)
      HRMAPI.FolderListing(
        this.sendObj.projectDetailId,
        this.sendObj.folderPath,
        this.sendObj.shortBy,
        this.sendObj.sortOrder,
        response => {
          if (response.Data !== null) {
            response.Data.sort(function (a, b) {
            console.log("1111111111");
            var nameA = a.uploadedOn
            var nameB = b.uploadedOn
              if (nameA < nameB) {
                return 1
              }
              if (nameA > nameB) {
                return -1
              }
              return 0
            })
          }
        console.log("~~~~~~~~~~~~~$$$$$$$$$$", response.Data);
          this.folderDataList = response.Data === null ? [] : response.Data
          if (this.routingData.length === 1) {
            this.routingData[0].color = '#494949'
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    FileManagerFileIcon(type) {
      var fileArray = type.split('.')
      if (fileArray.length > 1) {
        type = fileArray[fileArray.length - 1]
      }
      type = type.toLowerCase()
      if (type === 'pdf' || type === 'application/pdf') {
        return '<i class="h-5 w-5 fas fa-file-pdf"></i>'
      } else if (type === 'folder') {
        return '<i class="h-5 w-5 fas fa-folder"></i>'
      } else if (type === 'text/plain' || type === 'txt' || type === 'json') {
        return '<i class="h-5 w-5 fas fa-file-text"></i>'
      } else if (type === 'doc' || type === 'docx' || type === 'odt') {
        return '<i class="h-5 w-5 fas fa-file-word"></i>'
      } else if (type === 'xls' || type === 'xlsx' || type === 'ods') {
        return '<i class="h-5 w-5 fas fa-file-excel"></i>'
      } else if (type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'gif' || type === 'svg' || type === 'image/jpeg' || type === 'image/svg+xml' || type === 'image/webp' || type === 'text/xml; charset=utf-8' || type === 'image/png' || type === 'webp' ) {
        return '<i class="h-5 w-5 fas fa-image"></i>'
      } else if (type === 'zip' || type === 'rar' ||type === 'application/x-zip-compressed') {
        return '<i class="h-5 w-5 fas fa-zip"></i>'
      } else if (type === 'mp3' || type === 'wav' ||  type === 'audio/mpeg') {
        return '<i class="h-5 w-5 fas fa-music"></i>'
      } else if (type === 'mov' || type === 'mvi' || type === 'mp4' || type === 'mkv' || type === 'mpeg' || type === 'avi' || type === 'mpeg4' || type === 'video/x-matroska' || type === 'video/mp4'  || type === 'video/webm') {
        return '<i class="h-5 w-5 fas fa-file-video"></i>'
      } else {
        return '<i class="h-5 w-5 fas fa-folder"></i>'
      }
    },
    sortByColumn (type, i, val1, val2) {
      // if (val1 === true) {
      //   this.titleObj[i].sort = false
      // } else if(val1 === '') {
      //   this.titleObj[i].sort = true
      // } else {
      //   this.titleObj[i].sort = true
      // }
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      console.log('this.pagination.descending', this.pagination.descending)
      if (this.pagination.descending) {
        this.folderDataList.sort(function (a, b) {
          if (type === 'uploadedOn') {
            console.log("1111111111");
            var nameA = a.uploadedOn
            var nameB = b.uploadedOn
          } else if (type === 'size') {
            nameA = a.size
            nameB = b.size
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        this.folderDataList.reverse(function (a, b) {
           if (type === 'uploadedOn') {
            console.log("22222222222");
            var nameA = a.uploadedOn
            var nameB = b.uploadedOn
          } else if (type === 'size') {
            nameA = a.size
            nameB = b.size
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    }
  },
  beforeDestroy () {
    localStorage.removeItem('filterDataOfEmployee')
    this.$root.$off('Filter')
    this.$root.$off('Filters')
  }
}
</script>
<style scoped>
.name_col {
  min-width: 500px !important;
  width: 500px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.date_col {
  min-width: 300px !important;
  width: 300px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.size_col {
  min-width: 300px !important;
  width: 300px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.list_top_card_btn {
  width: 183px !important;
  margin-right: 15px !important;
}
.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}

::placeholder {
  font-size: 16px;
}
</style>
